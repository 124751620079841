<template>
  <section class="benner">
    <h1>{{ $t('Head.Lecturer') }}</h1>
    <!-- 讲师专区 -->
    <p>{{ $t('teacher.banner_tip') }}</p>
    <!-- 企业内训讲师资源，可以快速了解企业师资力量 -->
  </section>

  <section class="calendar">
    <div class="calendar-head">
      <span>
        {{ $t('teacher.total_today_nth_lesson', [1]) }} 
        <i>{{ todayTotal }}</i> 
        {{ $t('teacher.total_today_nth_lesson', [1]) }}
      </span>
      <!-- 今日共  节课-->
      <a-month-picker
        format="YYYY-MM"
        valueFormat="YYYY-MM"
        v-model:value="month"
        @change="monthChange"
      >
        <span class="month">
          {{ month }}
          <DownOutlined class="arrow" />
        </span>
      </a-month-picker>
    </div>
    <div class="calendar-box">
      <a-tabs v-model:activeKey="dayActive">
        <a-tab-pane v-for="(d, i) in days" :key="i + ''">
          <template #tab>
            {{ d.day }}
            <div class="dot" v-if="d.list.length"></div>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
  </section>

  <section class="list">
    <div class="content">
      <a-spin :spinning="loadding">
        <template v-if="days[Number(dayActive)].list.length">
          <div class="cards">
            <div
              class="item"
              v-for="item in days[Number(dayActive)].list"
              :key="item.courseId"
            >
              <div class="img">
                <img v-if="item.type == 8" :src="item.coverImg" alt="cover" />
                <template v-else-if="item.type == 9">
                  <img v-if="item.coverImg" :src="item.coverImg" alt="cover" />
                  <img
                    v-else
                    src="../../assets/image/live-cover.png"
                    alt="cover"
                  />
                </template>
              </div>
              <div class="info">
                <template v-if="item.type == 8">
                  <div class="title">
                    <span class="tag">{{ $t('offline') }}</span>
                    <!-- 线下 -->
                    <p>{{ item.courseName }}</p>
                  </div>
                  <div class="addr">
                    <EnvironmentOutlined />
                    <p>{{ item.address }}</p>
                  </div>
                  <div class="row">
                    <div class="time">
                      <ClockCircleOutlined />
                      <p>
                        {{ dateFormat(item.startTime) }} ~
                        {{ dateFormat(item.endTime) }}
                      </p>
                    </div>
                    <router-link
                      class="link"
                      :to="{
                        path: '/teacher/offline/detail',
                        query: { id: item.taskId, did: item.detailId },
                      }"
                      >{{ $t('view_detail') }}</router-link
                    >
                    <!-- 查看详情 -->
                  </div>
                </template>
                <template v-else-if="item.type == 9">
                  <div class="title">
                    <span class="tag">{{ $t('CM_Live') }}</span>
                    <!-- 直播 -->
                    <p>{{ item.detailName }}</p>
                  </div>
                  <div class="addr"></div>
                  <div class="row">
                    <div class="time">
                      <ClockCircleOutlined />
                      <p>
                        {{ dateFormat(item.startTime) }} ~
                        {{ dateFormat(item.endTime) }}
                      </p>
                    </div>
                    <template v-if="currentTime <= item.endTime">
                      <template v-if="item.liveType == 1">
                        <a
                          class="link"
                          :href="`polyvlive://start?channelId=${
                            item.roomId
                          }&passwd=${
                            item.roomPassword || item.channelPassword
                          }&pdn=api.polyv.net`"
                          >{{ $t('live.start_client') }}</a
                        >
                        <!-- 启动客户端 -->
                        <a
                          class="link"
                          :target="newWindow == 1 ? '_blank' : '_self'"
                          @click="webOpen(item)"
                        >
                        {{ $t('live.web_live') }}
                          <!-- 网页开播 -->
                        </a>
                      </template>
                      <template v-if="item.liveType == 2">
                        <a class="link" @click="wxOpen(item)">{{ $t('live.start_live') }}</a>
                        <!-- 开启直播 -->
                      </template>
                      <template v-if="item.liveType == 3">
                        <a class="link" @click="ddOpen(item)">{{ $t('live.start_live') }}</a>
                        <!-- 开启直播 -->
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { dateFormat } from "@/utils/tools";
import { trainCalendar, startLive } from "@/api/teacher";
import wxwork from "@/utils/wxwork";
// import { platformConfig } from "@/api/other";
// import { wxworkConfig } from "@/api/user";
// import { currentHost } from "@/utils/request.js";
export default {
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      month: moment().format("YYYY-MM"),
      dayActive: "0",
      days: [],
      loadding: true,
      todayTotal: 0,
    });
    const thisMonth = state.month;
    const today = moment().format("DD");
    const currentTime = Date.parse(new Date()) / 1000;
    let isFirst = true;
    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isWxWork = /wxwork/i.test(ua);
    const isMac = /macintosh|mac os x/i.test(ua);
    const isDingding = /dingtalk/i.test(ua);

    const getCourse = (time) => {
      trainCalendar({ time }).then((res) => {
        let d = res.data.list || [];
        for (let i = 0; i < d.length; i++) {
          let t1 = d[i].startTime * 1000,
            t2 = d[i].endTime * 1000;
          for (let j = 0; j < state.days.length; j++) {
            let date = moment(state.days[j].date).valueOf();
            if ((date >= t1|| date + 86300000>=t1) && date <= t2) {
              state.days[j].list.push(d[i]);
            } else if (t1 >= date && t2 <= date + 86400000) {
              state.days[j].list.push(d[i]);
            }
          }
        }
        if (isFirst) {
          state.todayTotal = state.days[Number(state.dayActive)].list.length;
          isFirst = false;
        }
        state.loadding = false;
      });
    };

    const dayRender = (n) => {
      if (state.month == thisMonth && Number(today) == n) {
        state.dayActive = n - 1 + "";
        return $t('XB_Today');
        // 今日
      } else {
        return n < 10 ? "0" + n : n;
      }
    };

    const monthChange = (val) => {
      state.loadding = true;
      let num = moment(val, "YYYY-MM").daysInMonth();
      let date = moment(val, "YYYY-MM").format("YYYY-MM");
      state.dayActive = "0";
      let arr = [];
      for (let i = 0; i < num; i++) {
        arr.push({
          date: date + (i < 10 ? "-0" + (i + 1) : "-" + (i + 1)),
          day: dayRender(i + 1),
          list: [],
        });
      }
      state.days = arr;
      getCourse(date + "-01");
    };
    monthChange(thisMonth);

    const webOpen = (item) => {
      startLive(item.detailId).then((res) => {
        let token = res.data;
        let url = "https://live.polyv.net/web-start/?channelId=" + item.roomId;
        let urlStr = `https://live.polyv.net/teacher/auth-login?channelId=${
          item.roomId
        }&token=${token}&redirect=${encodeURI(url)}`;
        if (store.getters.companyInfo.useNewWindow == 1) {
          window.open(urlStr);
        } else {
          window.location.href = urlStr;
        }
      });
    };

    const wxOpen = (item) => {
      if (isWxWork) {
        if (isMac) {
          proxy.$message.info($t('live.ios_tip'));
          // 苹果电脑不支持开启直播，请用企微直播应用开启直播
        } else {
          wxwork.startLiving(item.roomId);
        }
      } else {
        proxy.$message.info($t('live.wxwork_tip'));
        // 请用企业微信开启直播
      }
    };

    const ddOpen = (item) => {
      proxy.$message.info($t('live.is_open_dingding_live'));
      // 正在打开钉钉直播
      window.open(
        `dingtalk://dingtalkclient/action/start_uniform_live?liveUuid=${item.roomId}`
      );
      // if (isDingding) {
      // } else {
      //   proxy.$message.info("请用钉钉开启直播");
      // }
    };

    onMounted(() => {
      if (isWxWork) {
        wxwork.agentConfig();
      }
    });

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      dateFormat,
      ...toRefs(state),
      monthChange,
      webOpen,
      wxOpen,
      ddOpen,
      currentTime,
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  height: 324px;
  background-image: url("../../assets/image/teacher_calendar_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  .mixinFlex(center; center; column);
  h1 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 2px;
  }
  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 60px;
  }
}

.calendar {
  .mixinWrap();
  background-color: #fff;
  padding: 18px 30px 8px;
  border-radius: 4px;
  margin-top: -60px;
  &-head {
    .mixinFlex(space-between; center);
    line-height: 30px;
    font-size: 20px;
    i {
      color: @color-theme;
    }
    .month {
      line-height: 30px;
      font-size: 20px;
      cursor: pointer;
      .mixinFlex(space-between; center);
      .arrow {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
  &-box {
    padding: 10px 0 0;
    ::v-deep(.ant-tabs-bar) {
      border-bottom: 0;
      .ant-tabs-nav {
        .ant-tabs-tab {
          width: 66px;
          height: 66px;
          padding: 0;
          .mixinFlex(center; center);
          display: inline-flex;
          font-size: 18px;
          color: #d0d3dc;
          border-radius: 10px;
          border: 1px solid #d0d3dc;
          margin: 20px;
          &.ant-tabs-tab-active {
            color: #fff;
            background-color: @color-theme;
            box-shadow: 0 2px 12px 1px @color-theme;
            border-color: @color-theme;
          }
        }
      }
      .ant-tabs-ink-bar {
        height: 0;
      }
      .ant-tabs-tab-prev,
      .ant-tabs-tab-next {
        .anticon {
          font-size: 24px;
        }
      }
      .dot {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}

.list {
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      background-color: #fff;
      padding: 30px;
      .item {
        height: 201px;
        background-color: #f5f5f6;
        padding: 24px 20px;
        margin-bottom: 20px;
        .mixinFlex(space-between; center);
        &:last-child {
          margin-bottom: 0;
        }
        .img {
          .mixinImgWrap(216px; 141px);
        }
        .info {
          width: calc(100% - 240px);
          height: 141px;
          .title {
            .mixinFlex(space-between; center);
            margin-bottom: 16px;
            .tag {
              width: 40px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              border-radius: 2px;
              color: #fff;
              background-color: #a6adb7;
            }
            p {
              margin: 0;
              width: calc(100% - 50px);
              .mixinEllipsis(30px);
              color: #333;
              font-size: 16px;
            }
          }
          .addr,
          .time {
            .mixinFlex(flex-start; center);
            color: #999;
            height: 30px;
            p {
              margin: 0 0 0 10px;
              .mixinEllipsis(30px);
            }
          }
          .row {
            .mixinFlex(space-between; center);
            margin-top: 34px;
            .time {
              width: calc(100% - 210px);
            }
            .link {
              width: 80px;
              height: 28px;
              line-height: 26px;
              text-align: center;
              color: @color-theme;
              border: 1px solid @color-theme;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
